<template>
  <v-app style="background-color: #0a192f;">
    <v-main>
      <NavBar/>
      <v-container fluid
                   class="landing-page">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12"
                 md="8">
            <v-card class="icons-card rounded-card pa-4">
              <v-row class="d-flex justify-center align-center">
                <v-col v-for="(icon, index) in techIcons"
                       :key="index"
                       cols="4"
                       md="2"
                       class="d-flex justify-center">
                  <img :src="icon.src"
                       :alt="icon.alt"
                       class="tech-icon"/>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center section-spacing">
          <v-col cols="12"
                 md="8">
            <v-card class="intro-card rounded-card pa-5">
              <v-card-text class="intro-text text-center">
                Founded in 2024, Swift Solutions is a software company dedicated to delivering tailored personal and
                commercial software solutions. We specialize in client-business connectivity, data management, trend
                analysis, and innovative applications designed to optimize efficiency and drive success.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center section-spacing">
          <v-col cols="12"
                 md="8">
            <v-card class="faq-card rounded-card pa-5">
              <v-card-title
                  class="text-center">
                Frequently Asked Questions
              </v-card-title>
              <v-card-text>
                <v-row v-for="(question, index) in faq"
                       :key="index"
                       class="align-items-start mb-4">
                  <v-col cols="12"
                         md="6">
                    <p class="faq-question">
                      <strong>
                        {{ question.q }}
                      </strong>
                    </p>
                  </v-col>
                  <v-col cols="12"
                         md="6"
                         class="text-right">
                    <p class="faq-answer">
                      {{ question.a }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center section-spacing">
          <v-col v-for="(card, index) in cards"
                 :key="index"
                 cols="12"
                 md="4"
                 class="d-flex justify-center">
            <v-card class="custom-card d-flex flex-column align-center justify-center pa-5 rounded-card">
              <v-card-title class="text-center">
                {{ card.title }}
              </v-card-title>
              <v-card-text class="text-center">
                <div>
                  {{ card.company }}
                </div>
                <div class="role">
                  {{ card.role }}
                </div>
              </v-card-text>
              <v-card-actions class="d-flex justify-center">
                <v-btn icon
                       @click="goToGitHub(card.github)">
                  <v-icon>
                    mdi-github
                  </v-icon>
                </v-btn>
                <v-btn icon
                       @click="goToLinkedIn(card.linkedin)">
                  <v-icon>
                    mdi-linkedin
                  </v-icon>
                </v-btn>
                <v-btn icon
                       @click="sendEmail()">
                  <v-icon>
                    mdi-email
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer/>
  </v-app>
</template>
<script>
import {defineComponent} from 'vue';
import NavBar from './components/NavBar.vue';
import Footer from './components/Footer.vue';
import {mapGetters} from 'vuex';

export default defineComponent({
  components: {
    NavBar,
    Footer,
  },
  name: 'About',
  data() {
    return {
      faq: [
        {
          q: 'What services do you offer?',
          a: 'We offer customer and employee management software, data analytics, and more tailored solutions.'
        },
        {q: 'What technologies do you specialize in?', a: 'We specialize in Vue.js, Vuetify, Laravel, MySQL, & Swift.'},
        {
          q: 'Do you provide hosting solutions?',
          a: 'Yes, we provide scalable cloud hosting solutions for certain plans.'
        },
        {
          q: 'How can I get in touch with support?',
          a: 'You can reach our support team at admin@swiftsolutions.app or through our contact form.'
        },
        {q: 'What are your pricing plans?', a: 'We have multiple pricing plans suited to various business needs.'},
      ],
      cards: [
        {
          title: 'Leonardo Delprete',
          company: 'Swift Solutions',
          role: 'CEO, Software Engineer',
          github: 'https://github.com/leo307',
          linkedin: 'https://linkedin.com/in/leonardo-delprete',
        },
      ],
      techIcons: [
        {src: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuetify/vuetify-original.svg', alt: 'Vuetify Logo'},
        {src: 'https://cdn.worldvectorlogo.com/logos/laravel-2.svg', alt: 'Laravel Logo'},
        {src: 'https://upload.wikimedia.org/wikipedia/commons/9/95/Vue.js_Logo_2.svg', alt: 'Vue.js Logo'},
        {src: 'https://upload.wikimedia.org/wikipedia/en/d/dd/MySQL_logo.svg', alt: 'MySQL Logo'},
        {src: 'https://cdn.worldvectorlogo.com/logos/swift-15.svg', alt: 'Swift Logo'},
      ],
    };
  },
  methods: {
    goToGitHub(link) {
      window.open(link, '_blank');
    },
    goToLinkedIn(link) {
      window.open(link, '_blank');
    },
    sendEmail() {
      window.open('mailto:leo@swiftsolutions.app');
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
});
</script>

<style scoped>
.landing-page {
  background-color: #0a192f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: 'Roboto', sans-serif;
}

.intro-card {
  background-color: #112240;
  color: #d0d6d6;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.intro-text {
  font-size: 18px;
  line-height: 1.8;
  font-weight: 500;
}

.icons-card {
  background-color: #112240;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.tech-icon {
  max-width: 50px;
  max-height: 50px;
  border-radius: 8px;
  transition: transform 0.3s;
}

.tech-icon:hover {
  transform: scale(1.1);
}

.faq-card {
  background-color: #112240;
  color: #d0d6d6;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.faq-question {
  margin-bottom: 10px;
  font-weight: bold;
  color: #ffffff;
}

.faq-answer {
  margin-bottom: 15px;
}

.custom-card {
  background-color: #112240;
  color: #d0d6d6;
  width: 100%;
  height: auto;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.section-spacing {
  margin-bottom: 30px;
}

.text-center {
  text-align: center;
}

.v-card-title {
  color: #ffffff;
  font-weight: bold;
}

.v-btn {
  background-color: #3eb3a5;
  color: white;
  margin: 0 5px;
}

.v-btn:hover {
  background-color: #34a493;
}

.role {
  font-style: italic;
  color: #b0c4de;
}
</style>
