import {createRouter, createWebHistory} from 'vue-router'
import Landing from '../views/front/Landing.vue'
import About from '../views/front/About.vue'
import Contact from '../views/front/Contact.vue'
import PrivacyPolicy from '../views/privacy/PrivacyPolicy.vue'
import Pricing from '../views/front/Pricing.vue'
import Login from '../views/front/Login.vue'
import Register from '../views/front/Register.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'

const routes = [
    // FRONT
    {
        path: '/',
        name: 'home',
        component: Landing,
    },
    {
        path: '/about',
        name: 'about',
        component: About,
    }, {
        path: '/faq',
        name: 'faq',
        component: About,
    },

    {
        path: '/contact',
        name: 'contact',
        component: Contact,
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: Pricing,
    },
    // Privacy
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy,
    },
    // Auth
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
    },
    // Dashboard
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {requiresAuth: true},
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const token = localStorage.getItem('token');
        if (!token) {
            next({name: 'login'});
        } else {
            next();
        }
    } else {
        next();
    }
});


export default router
