<template>
  <v-card v-if="user && localSolution">
    <v-card-title>
      Manage {{ localSolution.name }}
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent="saveSolution">
        <v-text-field v-model="localSolution.name"
                      label="Name"
                      required>
        </v-text-field>
        <v-row>
          <v-col cols="3">
            <v-select v-model="localSolutionStatus"
                      :items="['Active', 'Inactive']"
                      label="Solution Status"
                      dense
                      required>
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-select v-model="localSolutionStorage"
                      :items="['On', 'Off']"
                      label="Storage Status"
                      dense
                      required>
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-select v-model="localSolutionHosting"
                      :items="['On', 'Off']"
                      label="Hosting Status"
                      dense
                      required>
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-select v-model="localSolutionAnalytics"
                      :items="['On', 'Off']"
                      label="Analytics Status"
                      dense
                      required>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="align-center justify-space-between">
          <v-col cols="4">
            <v-btn color="#FFA500"
                   :disabled="localSolutionSupport === 'Off'">
              Request Support
            </v-btn>
            <div v-if="localSolutionSupport === 'No'">
              <p class="text-center">
                <strong>
                  Please contact staff to upgrade your solution to include Support.
                </strong>
              </p>
            </div>
          </v-col>
          <v-col cols="4"
                 class="text-center">
            <p>
              <strong>
                Current solution price: ${{ localSolution.price }}
              </strong>
              <br>
              <a style="color: white"
                 href="https://swiftsolutions.app/pricing">
                Manage Billing
              </a>
            </p>
          </v-col>
          <v-col cols="4"
                 class="text-right">
            <v-btn color="#FFA500"
                   :disabled="localSolutionMaintenance === 'No'">
              Request Maintenance
            </v-btn>
            <div v-if="localSolutionMaintenance === 'No'">
              <p class="text-center">
                <strong>
                  Please contact staff to upgrade your solution to include maintenance.
                </strong>
              </p>
            </div>
          </v-col>
        </v-row>
        <div class="d-flex justify-end mt-3">
          <v-btn color="error"
                 class="mr-3"
                 @click="shutdownSolution">
            DELETE SOLUTION
          </v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn color="secondary"
                 class="mr-3"
                 @click="cancelEdit">
            Cancel
          </v-btn>
          <v-btn color="primary"
                 @click="saveSolution">
            Save
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
  <v-card v-else>
    <v-card-text>
      <p class="text-center">
        Your solution could not be loaded, please try again or purchase a solution if you do not have one.
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    solution: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      localSolution: null,
      localSolutionStatus: null,
      localSolutionHosting: null,
      localSolutionAnalytics: null,
      localSolutionStorage: null,
      localSolutionMonthly: null,
      localSolutionSupport: null,
      localSolutionMaintenance: null,
      price: null,
    };
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        if (newUser && newUser.solution) {
          this.initializeLocalSolution(newUser.solution);
        }
      },
    },
    solution: {
      immediate: true,
      handler(newSolution) {
        if (newSolution) {
          this.initializeLocalSolution(newSolution);
        }
      },
    },
  },
  methods: {
    initializeLocalSolution(solution) {
      this.localSolution = {...solution};
      this.localSolutionStatus = solution.active ? 'Active' : 'Inactive';
      this.localSolutionHosting = solution.hosting ? 'On' : 'Off';
      this.localSolutionAnalytics = solution.analytics ? 'On' : 'Off';
      this.localSolutionStorage = solution.storage ? 'On' : 'Off';
      this.localSolutionMonthly = solution.monthly ? 'Yes' : 'No';
      this.localSolutionSupport = solution.support ? 'Yes' : 'No';
      this.localSolutionMaintenance = solution.maintenance ? 'Yes' : 'No';
      this.price = solution.price || 0;
    },
    async saveSolution() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          this.$router.push({name: "login"});
          return;
        }
        const updatedSolution = {
          name: this.localSolution.name,
          active: this.localSolutionStatus === 'Active' ? 1 : 0,
          hosting: this.localSolutionHosting === 'On' ? 1 : 0,
          analytics: this.localSolutionAnalytics === 'On' ? 1 : 0,
          storage: this.localSolutionStorage === 'On' ? 1 : 0,
          monthly: this.localSolutionMonthly === 'Yes' ? 1 : 0,
          support: this.localSolutionSupport === 'Yes' ? 1 : 0,
          maintenance: this.localSolutionMaintenance === 'Yes' ? 1 : 0,
          price: this.price,
        };
        await axios.put(`https://swiftsolutions.app/api/solutions/${this.localSolution.id}`, updatedSolution, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert("Solution updated successfully!");
      } catch (error) {
        console.error("Error updating solution:", error);
        alert("Failed to update the solution. Please try again.");
      }
    },
    cancelEdit() {
      this.initializeLocalSolution(this.solution);
    },
    async shutdownSolution() {
      if (!confirm("You are attempting to shutdown your solution. This may cause issues if maintenance is in progress. If you want to delete your solution, please contact support.")) {
        return;
      }
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          this.$router.push({name: "login"});
          return;
        }
        await axios.delete(`https://swiftsolutions.app/api/solutions/${this.localSolution.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert("Solution shutdown successfully!");
        this.localSolution = null;
      } catch (error) {
        console.error("Error shutting down solution:", error);
        alert("Failed to shutdown solution. Please try again.");
      }
    },
  },
};
</script>
<style scoped>
.solution-title {
  color: #ffa500;
  font-size: 24px;
  font-weight: bold;
}

.v-card-text {
  background-color: #042630;
  color: #d0d6d6;
}

.v-btn {
  background-color: #042630;
  color: white;
}
</style>
