<template>
  <v-app style="background-color: #0a192f;">
    <NavBar>
    </NavBar>
    <v-main>
      <v-container fluid
                   class="landing-page">
        <v-row justify="center"
               class="feature-comparison-section"
               align="center">
          <v-col cols="12"
                 md="10">
            <v-row class="feature-comparison-content"
                   align="center">
              <v-col cols="12"
                     md="6">
                <v-img src="/images/demo.jpg"
                       class="comparison-image"
                       style="border-radius: 12px">
                </v-img>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="text-section"
                     align="center">
                <h1 class="floating-text text-white">
                  Transform your workflow with seamless integration and effortless communication - work smarter, not
                  harder.
                </h1>
                <ul class="feature-list">
                  <li>
                    Real-time Data Analytics for Better Decision Making
                  </li>
                  <li>
                    Fully Customizable to Fit Your Business Needs
                  </li>
                  <li>
                    24/7 Support and Maintenance
                  </li>
                </ul>
                <v-btn href="https://demo.swiftsolutions.app"
                       class="cta-button">
                  Explore Demo
                </v-btn>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center"
               class="why-section"
               align="center">
          <v-col cols="12"
                 md="8">
            <h2 class="why-heading mb-15">
              Why Swift Solutions
            </h2>
            <v-row>
              <v-col cols="12"
                     md="6"
                     class="animate__animated animate__fadeInLeft">
                <v-card class="why-card">
                  <v-card-title class="text-center">
                    Management: Our Solution
                  </v-card-title>
                  <v-card-text>
                    <p class="why-text text-center">
                      A comprehensive admin panel empowers managers to effortlessly oversee employee management,
                      streamline communication, handle applications, train new hires, and efficiently manage multiple
                      locations with clear employee assignments.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12"
                     md="6"
                     class="animate__animated animate__fadeInRight">
                <v-card class="why-card">
                  <v-card-title class="text-center">
                    Analytics: Increase Profitability
                  </v-card-title>
                  <v-card-text>
                    <p class="why-text text-center">
                      The analytics provided allow businesses to share that with their marketing team and develop
                      strategies for what's working based on the provided data. The data is presented in easy-to-read
                      graphs, and additional analytics can be provided upon request.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center"
               class="final-cta-section"
               align="center">
          <v-col cols="12"
                 md="8"
                 class="text-center">
            <h2 class="cta-heading">
              Ready to Transform Your Business?
            </h2>
            <p class="cta-description">
              Join our growing list of clients who have leveraged our solutions. Or visit our contact page to have any
              inquiries answered.
            </p>
            <v-btn class="cta-button">
              Get Started Now
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>
<script>
import Footer from "@/views/front/components/Footer.vue";
import NavBar from './components/NavBar.vue';

export default {
  components: {
    NavBar,
    Footer,
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.landing-page {
  background-color: #0a192f;
  font-family: 'Roboto', sans-serif;
  padding: 0 20px;
}

.feature-comparison-section {
  padding: 80px 0;
  background-color: #0a192f;
}

.comparison-image {
  border-radius: 12px;
  width: 100%;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.text-section {
  text-align: center;
  color: #fff;
  padding: 20px;
}

.floating-text {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.feature-list {
  list-style-type: disc;
  text-align: left;
  padding: 10px 20px;
  margin: 20px 0;
  color: #f0f0f0;
}

.feature-list li {
  font-size: 1.1rem;
  margin: 5px 0;
}

.cta-button {
  background-color: #00bfa5;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.cta-button:hover {
  background-color: #009688;
}

.why-section {
  padding: 80px 0;
}

.why-heading {
  font-size: 2.5rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
}

.why-card {
  background: linear-gradient(145deg, #1a2b45, #0f1e34);
  padding: 30px;
  border-radius: 15px;
  color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.why-text {
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 15px;
}

.final-cta-section {
  padding: 80px 0;
  background-color: #112240;
}

.cta-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
}

.cta-description {
  color: #b0c4de;
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.large-cta {
  padding: 14px 40px;
  font-size: 1.2rem;
  background-color: #00bfa5;
  color: white;
  border-radius: 10px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.large-cta:hover {
  background-color: #009688;
}

@media only screen and (max-width: 768px) {
  .feature-comparison-section {
    padding: 40px 0;
  }

  .comparison-image {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
  }

  .floating-text {
    font-size: 1.5rem;
  }

  .feature-list {
    padding: 5px 0;
    margin: 10px 0;
    text-align: center;
  }

  .why-heading {
    font-size: 2rem;
  }

  .why-card {
    padding: 20px;
  }

  .why-text {
    font-size: 1rem;
  }

  .final-cta-section {
    padding: 40px 0;
  }

  .cta-heading {
    font-size: 1.8rem;
  }

  .cta-description {
    font-size: 1rem;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .text-section {
    padding: 10px;
  }
}
</style>
