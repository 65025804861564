<template>
  <v-app style="background-color: #0a192f;">
    <v-main>
      <NavBar />
      <v-container fluid>
        <v-row justify="center">
          <v-col v-for="tier in tiers" :key="tier.id" cols="12" sm="6" md="4" lg="3" class="d-flex align-center justify-center">
            <TierCard :tier="tier" />
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-card color="#112240" class="demo-card hover-card">
            <v-card-title class="text-center">
              <strong>Demo Our Solutions</strong>
            </v-card-title>

            <div>
              <v-img></v-img>
              <v-divider></v-divider>
              <v-card color="#112240">
                <v-card-actions>
                  <v-btn color="teal" href="https://swiftsolutions.app/service" block>
                    Enter Service Demo
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div>
              <v-img></v-img>
              <v-divider></v-divider>
              <v-card color="#112240">
                <v-card-actions>
                  <v-btn color="teal" href="https://swiftsolutions.app/retail" block>
                    Enter Retail Demo
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </v-card>
        </v-col>
        <v-row />
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import axios from 'axios';
import NavBar from './components/NavBar.vue';
import Footer from './components/Footer.vue';
import TierCard from './components/TierCard.vue';
import { mapGetters } from 'vuex';
import router from "@/router";

export default {
  name: 'Pricing',
  components: {
    NavBar,
    Footer,
    TierCard,
  },
  data() {
    return {
      tiers: [],
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  mounted() {
    this.fetchTiers();
  },
  methods: {
    async fetchTiers() {
      try {
        const response = await axios.get('/tiers');
        this.tiers = response.data;
      } catch (error) {
        console.error('Error fetching tiers:', error);
      }
    },
    demos() {
      router.push('/service');
    },
  },
};
</script>

<style scoped>
.demo-card {
  border-radius: 10px;
  padding: 20px;
}

.hover-card {
  position: relative;
  border-radius: 15px;
  height: 100%;
  background-color: #112240;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px;
}

.hover-card:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0px 15px 40px rgba(0, 191, 165, 0.6); /* Teal glow */
}

.discount-banner {
  background-color: #00bfa5; /* Teal accent */
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.tier-title {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-top: 40px;
}

.pricing-info {
  color: white;
  margin: 20px 0;
}

.strikethrough {
  text-decoration: line-through;
  color: #00bfa5; /* Teal accent */
}

.discount-price {
  font-size: 28px;
  font-weight: bold;
  color: white;
}

.features p {
  color: #d0d6d6;
  margin: 8px 0;
  padding: 0 15px;
}

.ios-feature {
  color: #d0d6d6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.ios-feature.highlighted {
  background-color: #00bfa5;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
}

.ios-feature .v-icon {
  font-size: 20px;
}

.ios-feature span {
  margin-left: 8px;
}

.subscribe-btn {
  margin-top: 20px;
  font-weight: 600;
  background-color: #00bfa5; /* Teal accent */
  border-radius: 10px;
  width: 100%;
}

.v-dialog .v-card {
  background-color: #112240;
  color: white;
  padding: 20px;
}

.v-dialog .v-card-actions {
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
