<template>
  <v-app style="background-color: #0a192f;">
    <v-main v-if="account">
      <NavBar/>
      <v-container fluid
                   class="dashboard-page">
        <v-row justify="center"
               class="mt-10 mb-10">
          <v-col cols="12"
                 md="8">
            <TitleCard :account="account"/>
          </v-col>
        </v-row>
        <v-row justify="center"
               class="mt-10 mb-10">
          <v-col cols="12"
                 md="8">
            <v-card>
              <v-tabs v-model="tab"
                      bg-color="#112240">
                <v-tab value="one"
                       class="tab-item">
                  Account
                </v-tab>
                <v-tab value="two"
                       class="tab-item">
                  {{ account.name }}'s Solution
                </v-tab>
                <v-tab value="three"
                       class="tab-item">
                  Settings
                </v-tab>
              </v-tabs>
              <v-tabs-window v-model="tab">
                <v-tabs-window-item value="one">
                  <AccountTab :account="account"/>
                </v-tabs-window-item>
                <v-tabs-window-item value="two">
                  <SolutionsTab :account="account"
                                :solution="account.solution"/>
                </v-tabs-window-item>
                <v-tabs-window-item value="three">
                  <SettingsTab :account="account"/>
                </v-tabs-window-item>
              </v-tabs-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-main v-else style="background-color: #0a192f;">
      <v-container>
        <v-card>
          <v-card-title>
            <h2 class="text-white">
              <strong>
                Dashboard could not be displayed please try again later.
              </strong>
            </h2>
          </v-card-title>
        </v-card>
      </v-container>
    </v-main>
    <Footer/>
  </v-app>
</template>
<script>
import axios from "axios";
import NavBar from "@/views/front/components/NavBar.vue";
import Footer from "@/views/front/components/Footer.vue";
import AccountTab from "@/views/dashboard/components/AccountTab.vue";
import SolutionsTab from "@/views/dashboard/components/SolutionsTab.vue";
import SettingsTab from "@/views/dashboard/components/SettingsTab.vue";
import TitleCard from "@/views/dashboard/components/TitleCard.vue";
import {mapGetters} from 'vuex';

export default {
  components: {
    NavBar,
    Footer,
    AccountTab,
    SolutionsTab,
    SettingsTab,
    TitleCard,
  },
  data() {
    return {
      account: null,
      error: null,
      tab: 0,
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  created() {
    this.fetchaccountAccount();
  },
  methods: {
    async fetchaccountAccount() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("SS API: No token found, please login again.");
          this.$router.push({name: "login"});
          return;
        }
        const response = await axios.get("/account", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          this.account = response.data;
        } else {
          console.error("SS API returned no account data.");
          this.error = "Failed to fetch your account.";
        }
      } catch (error) {
        console.error("SS API request error:", error);
        this.error = "SS API Failed to fetch your account. Please try again.";
        this.$router.push({name: "login"});
      }
    },
  },
};
</script>

<style scoped>
.dashboard-page {
  background-color: #0a192f;
  min-height: 100vh;
  padding-bottom: 100px;
}

.v-card-title {
  color: #FFA500;
}

.v-tabs {
  background-color: #051929;
}

.v-tabs-item {
  color: #112240;
}

.v-card {
  background-color: #042630;
  color: #d0d6d6;
}
</style>
