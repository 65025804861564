<template>
  <v-footer class="footer-section">
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <h3 class="footer-title">
            Swift Solutions
          </h3>
          <p class="footer-text">
            Empowering businesses with innovative tools and services to drive success and efficiency.
          </p>
        </v-col>
        <v-col cols="12"
               md="4">
          <h3 class="footer-title">
            Quick Links
          </h3>
          <v-list dense
                  class="footer-links">
            <v-list-item href="/about"
                         class="footer-list-item">
              <v-list-item-title>
                About Us
              </v-list-item-title>
            </v-list-item>
            <v-list-item href="/faq"
                         class="footer-list-item">
              <v-list-item-title>
                Frequently Asked Questions
              </v-list-item-title>
            </v-list-item>
            <v-list-item href="/contact"
                         class="footer-list-item">
              <v-list-item-title>
                Contact
              </v-list-item-title>
            </v-list-item>
            <v-list-item href="/privacy-policy"
                         class="footer-list-item">
              <v-list-item-title>
                Privacy Policy
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12"
               md="4">
          <h3 class="footer-title">
            Connect with Us
          </h3>
          <p class="footer-text">
            Follow us on our social media platforms for the latest updates.
          </p>
          <v-btn icon href="https://facebook.com"
                 class="footer-icon-btn">
            <v-icon>
              mdi-facebook
            </v-icon>
          </v-btn>
          <v-btn icon href="https://twitter.com"
                 class="footer-icon-btn">
            <v-icon>
              mdi-twitter
            </v-icon>
          </v-btn>
          <v-btn icon href="https://linkedin.com"
                 class="footer-icon-btn">
            <v-icon>
              mdi-linkedin
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider>
      </v-divider>
      <v-row>
        <v-col cols="12"
               class="footer-copyright">
          <p style="color: #f0e4d7">&copy;
            2024 Swift Solutions. All rights reserved.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: 'Footer',
};
</script>
<style>
.footer-section {
  background-color: #04101a;
  color: #f0e4d7;
  padding: 40px 20px;
  border-radius: 12px;
  margin-top: 20px;
}

.footer-title {
  font-size: 20px;
  margin-bottom: 20px;
  color: #f0e4d7;
}

.footer-text {
  font-size: 16px;
  color: #f0e4d7;
}

.footer-links {
  background-color: inherit !important;
  padding: 0;
}

.footer-list-item {
  background-color: transparent !important;
  color: #f0e4d7;
}

.footer-links .v-list-item:hover {
  text-decoration: underline;
}

.footer-icon-btn {
  color: #f0e4d7;
  margin-right: 10px;
}

.footer-icon-btn:hover {
  color: #1e88e5;
}

.footer-copyright {
  text-align: center;
  margin-top: 20px;
  color: white;
}
</style>
