<template>
  <v-card v-if="account"
          class="dashboard-card rounded-card pa-5"
          style="background-color: #112240">
    <v-card-title class="text-center">
      Welcome to your dashboard {{ account.company }}
    </v-card-title>
    <v-card-text class="text-center">
      Manage your company account, custom solution, and more.
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    account: {
      type: Object,
      required: true
    },
  },
}
</script>
<style>
.dashboard-card {
  background-color: #112240;
  color: #112240;
  border-radius: 12px;
}
</style>