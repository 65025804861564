<template>
  <v-card-text v-if="user">
<h1>
  {{ user.name }}
</h1>
  </v-card-text>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
}
</script>