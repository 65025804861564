<template>
  <v-card v-if="solution">
    <v-card-title>
      Edit Profile
    </v-card-title>
    <pre></pre>
    <v-card-text>
      <v-form ref="form"
              v-model="valid"
              lazy-validation>
        <v-text-field v-model="editedsolution.name"
                      label="Name"
                      required>
        </v-text-field>
        <v-text-field v-model="editedsolution.email"
                      label="Email"
                      type="email"
                      required>
        </v-text-field>
        <v-text-field v-model="editedsolution.company"
                      label="Company">
        </v-text-field>
        <v-text-field v-model="editedsolution.company_website"
                      label="Company Website">
        </v-text-field>
        <v-text-field v-model="editedsolution.country"
                      label="Country">
        </v-text-field>
        <v-text-field v-model="editedsolution.number"
                      label="Number">
        </v-text-field>
        <div class="d-flex justify-end mt-3">
          <v-btn @click="resetPassword"
                 color="error"
                 class="mr-3">
            Reset Password
          </v-btn>
          <v-btn @click="deletesolution"
                 color="error"
                 class="mr-3">
            Delete solution
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="cancelEdit"
                 color="secondary"
                 class="mr-3">
            Cancel
          </v-btn>
          <v-btn @click="saveProfile"
                 :disabled="!valid"
                 color="primary">
            Save
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
  <div v-else>
    <p>Loading profile...</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    solution: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      valid: true,
      editedsolution: {...this.solution},
    };
  },
  watch: {
    solution(newsolution) {
      this.editedsolution = {...newsolution};
    },
  },
  methods: {
    async saveProfile() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          this.$router.push({ name: "login" });
          return;
        }
        const response = await axios.put("/solution", this.editedsolution, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.$emit("save-profile", response.data.solution);
        alert("Profile updated successfully!");
      } catch (error) {
        console.error("Error updating profile:", error);
        alert("Failed to update profile. Please try again.");
      }
    },
    cancelEdit() {
      this.editedsolution = { ...this.solution };
    },
    async resetPassword() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          this.$router.push({ name: "login" });
          return;
        }
        await axios.post("/reset-password", { email: this.solution.email }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert("Password reset email sent!");
      } catch (error) {
        console.error("Error sending password reset email:", error);
        alert("Failed to reset password. Please try again.");
      }
    },
    async deletesolution() {
      if (!confirm("Are you sure you want to delete your solution? This action cannot be undone.")) {
        return;
      }
      try {
        const token = localStorage.getItem("token");
        await axios.delete("/solution", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert("solution deleted successfully!");
        this.$router.push({ name: "login" });
      } catch (error) {
        console.error("Error deleting solution:", error);
        alert("Failed to delete solution. Please try again.");
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  background-color: #042630;
  color: #d0d6d6;
  border-radius: 12px;
}
</style>
