<template>
  <v-card color="#112240"
          class="hover-card d-flex flex-column align-center justify-center p-4 rounded-lg"
          :style="{ minHeight: '500px', width: '400px' }"> <!-- Increased width -->
    <div v-if="tier.discount"
         class="discount-banner">
      <span>
        DISCOUNTED
      </span>
    </div>
    <p class="text-center tier-title">
      {{ tier.name }}
    </p>
    <v-divider class="mx-4"></v-divider>
    <div class="pricing-info text-center mt-4">
      <h3>
        <span class="original-price"
              v-if="tier.discount">
          <span class="strikethrough">
            ${{ tier.price }}
          </span>
        </span>
        <span class="discount-price">
          ${{ tier.discount_price }} / Month
        </span>
      </h3>
    </div>
    <div class="features text-center mt-4">
      <p><strong>Status: </strong>{{ tier.active ? 'Active' : 'Inactive' }}</p>
      <div class="ios-feature d-flex justify-center align-center mt-2" :class="{ 'highlighted': tier.app }">
        <v-icon left color="white">mdi-apple</v-icon>
        <strong class="ml-1">iOS App:</strong>
        <span class="ml-1">{{ tier.app ? 'Included' : 'Not Included' }}</span>
      </div>
      <p><strong>Updates: </strong>{{ tier.updates ? 'Included' : 'Not Included' }}</p>
      <p><strong>Analytics: </strong>{{ tier.analytics ? 'Included' : 'Not Included' }}</p>
      <p><strong>Server: </strong>{{ tier.server ? 'Included' : 'Not Included' }}</p>
      <p><strong>Support: </strong>{{ tier.support ? 'Included' : 'Not Included' }}</p>
      <p><strong>Maintenance: </strong>{{ tier.maintenance ? 'Included' : 'Not Included' }}</p>
    </div>
    <v-btn class="subscribe-btn mt-4" color="teal" @click.stop="subscribe(tier.name)"> <!-- Teal Button -->
      Subscribe
    </v-btn>
    <v-dialog v-model="dialogVisible" max-width="600">
      <v-card>
        <v-card-title class="headline">{{ tier.name }} Details</v-card-title>
        <v-card-text>
          <p><strong>Price: </strong>${{ tier.price }} / Month</p>
          <p><strong>Status: </strong>{{ tier.active ? 'Active' : 'Inactive' }}</p>
          <div class="ios-feature d-flex justify-center align-center mt-2" :class="{ 'highlighted': tier.app }">
            <v-icon left color="white">mdi-apple</v-icon>
            <strong class="ml-1">iOS App:</strong>
            <span class="ml-1">{{ tier.app ? 'Included' : 'Not Included' }}</span>
          </div>
          <p><strong>Analytics: </strong>{{ tier.analytics ? 'Included' : 'Not Included' }}</p>
          <p><strong>Support: </strong>{{ tier.support ? 'Included' : 'Not Included' }}</p>
          <p><strong>Maintenance: </strong>{{ tier.maintenance ? 'Included' : 'Not Included' }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="dialogVisible = false" class="mb-4">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import router from "@/router";

export default {
  props: {
    tier: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    subscribe(tierName) {
      if (this.isLoggedIn) {
        alert(`You have selected the ${tierName} tier.`);
      } else {
        alert('Please create an account to purchase a custom solution.');
        router.push('/register');
      }
    },
  }
}
</script>

<style scoped>
.hover-card {
  position: relative;
  border-radius: 15px;
  height: 100%;
  background-color: #112240;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px; /* Added padding */
}

.hover-card:hover {
  transform: translateY(-10px) scale(1.03);
  box-shadow: 0px 15px 40px rgba(0, 191, 165, 0.6); /* Teal glow */
}

.discount-banner {
  background-color: red; /* Red discount banner */
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.tier-title {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-top: 40px;
}

.pricing-info {
  color: white;
  margin: 20px 0;
}

.strikethrough {
  text-decoration: line-through;
  color: red; /* Red strikethrough */
}

.discount-price {
  font-size: 28px;
  font-weight: bold;
  color: white;
}

.features p {
  color: #d0d6d6;
  margin: 10px 0;
  padding: 0 15px; /* Added padding to feature list */
}

.ios-feature {
  color: #d0d6d6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.ios-feature.highlighted {
  background-color: #00bfa5; /* Teal highlight */
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
}

.ios-feature .v-icon {
  font-size: 20px;
}

.ios-feature span {
  margin-left: 8px;
}

.subscribe-btn {
  margin-top: 20px;
  font-weight: 600;
  background-color: #00bfa5; /* Teal button */
  border-radius: 10px;
  width: 90%;
}

.v-dialog .v-card {
  background-color: #112240;
  color: white;
  padding: 20px; /* Added padding */
}

.v-dialog .v-card-actions {
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
