<template>
  <v-app-bar style="background-color: transparent; max-width: 100%; padding: 0;"
             flat>
    <v-app-bar-nav-icon v-if="isMobile"
                        @click="drawer = !drawer"
                        color="white">
    </v-app-bar-nav-icon>
    <v-menu v-model="drawer">
      <v-list style="background-color: #0a192f">
        <v-list-item @click="navigateTo('home')">
          <v-list-item-title class="text-white">
            Home
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="navigateTo('pricing')">
          <v-list-item-title class="text-white">
            Pricing / Solutions
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="navigateTo('about')">
          <v-list-item-title class="text-white">
            About
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="navigateTo('contact')">
          <v-list-item-title class="text-white">
            Contact
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="navigateTo('collection')">
          <v-list-item-title class="text-white">
            Collection
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isLoggedIn"
                     @click="navigateTo('dashboard')">
          <v-list-item-title class="text-white">
            Dashboard
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!isLoggedIn"
                     @click="navigateTo('login')">
          <v-list-item-title class="text-white">
            Login
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-else
                     @click="logout">
          <v-list-item-title class="text-white">
            Logout
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-img v-if="!isMobile"
           src="/images/logo.png"
           alt="Logo"
           class="logo">
    </v-img>
    <v-spacer v-if="!isMobile">
    </v-spacer>
    <div v-if="!isMobile"
         class="nav-buttons">
      <v-btn :to="{ name: 'home' }"
             color="white">
        Home
      </v-btn>
      <v-btn :to="{ name: 'pricing' }"
             color="white">
        Pricing / Solutions
      </v-btn>
      <v-btn :to="{ name: 'about' }"
             color="white">
        About
      </v-btn>
      <v-btn :to="{ name: 'contact' }"
             color="white">
        Contact
      </v-btn>
      <v-btn color="white">Collection
      </v-btn>
      <v-btn v-if="isLoggedIn"
             :to="{ name: 'dashboard' }"
             color="white">Dashboard
      </v-btn>
    </div>
    <v-spacer v-if="!isMobile">
    </v-spacer>
    <v-btn v-if="!isMobile && !isLoggedIn"
           :to="{ name: 'login' }"
           color="white">
      Login
    </v-btn>
    <v-btn v-else-if="!isMobile"
           @click="logout"
           color="white">
      Logout
    </v-btn>
  </v-app-bar>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  data() {
    return {
      drawer: false,
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    ...mapActions(['logout']),
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    navigateTo(routeName) {
      this.drawer = false;
      this.$router.push({name: routeName});
    },
  },
  mounted() {
    this.$store.dispatch('checkAuthStatus');
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
};
</script>
<style scoped>
.logo {
  max-width: 100px;
  max-height: 60px;
  margin-left: 0;
  padding-left: 0;
}

.nav-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.v-btn {
  color: white;
  font-weight: bold;
}

.mobile-drawer {
  background-color: #0a192f;
}

.v-list-item-title {
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .nav-buttons {
    display: none;
  }
}
</style>
