import { createStore } from 'vuex';
import axios from 'axios';
import router from '../router';

const store = createStore({
    state: {
        isLoggedIn: false,
        user: null,
        cartItems: [],
        config: {},
    },
    mutations: {
        REMOVE_FROM_CART(state, itemId) {
            state.cartItems = state.cartItems.filter((item) => item.id !== itemId);
        },
        ADD_TO_CART(state, item) {
            const existingItem = state.cartItems.find((cartItem) => cartItem.id === item.id);
            if (existingItem) {
                existingItem.quantity += item.quantity;
            } else {
                state.cartItems.push(item);
            }
        },
        UPDATE_CART_QUANTITY(state, item) {
            const existingItem = state.cartItems.find((cartItem) => cartItem.id === item.id);
            if (existingItem) {
                existingItem.quantity = item.quantity;
            }
        },
        SET_LOGGED_IN(state, status) {
            state.isLoggedIn = status;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        SET_CONFIG(state, config) {
            state.config = config;
        }
    },
    actions: {
        removeFromCart({ commit }, itemId) {
            commit("REMOVE_FROM_CART", itemId);
        },
        addToCart({ commit }, item) {
            commit("ADD_TO_CART", item);
        },
        updateCartQuantity({ commit }, item) {
            commit("UPDATE_CART_QUANTITY", item);
        },
        async checkAuthStatus({ commit }) {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.get('/account', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    commit('SET_LOGGED_IN', true);
                    commit('SET_USER', response.data);
                } catch (error) {
                    commit('SET_LOGGED_IN', false);
                    commit('SET_USER', null);
                    localStorage.removeItem('token');
                }
            } else {
                commit('SET_LOGGED_IN', false);
                commit('SET_USER', null);
            }
        },
        logout({ commit }) {
            localStorage.removeItem('token');
            router.push('/login');
            commit('SET_LOGGED_IN', false);
            commit('SET_USER', null);
        },
        async fetchConfig({ commit }) {
            try {
                const accountId = localStorage.getItem('account_id');
                const response = await axios.get('/config', {
                    params: {
                        account_id: accountId,
                    },
                });
                commit('SET_CONFIG', response.data);
            } catch (error) {
                console.error('Failed to fetch configuration.', error);
            }
        }
    },
    getters: {
        cartItems: (state) => state.cartItems,
        cartTotal: (state) => {
            return state.cartItems.reduce((total, item) => {
                return total + item.price * item.quantity;
            }, 0);
        },
        cartCount: (state) => {
            return state.cartItems.reduce((count, item) => {
                return count + item.quantity;
            }, 0);
        },
        isLoggedIn: (state) => state.isLoggedIn,
        user: (state) => state.user,
        config: (state) => state.config,
    },
});

export default store;
