<template>
  <v-app style="background-color: #0a192f;">
    <v-main>
      <NavBar/>
      <v-container fluid class="contact-page">
        <v-card class="contact-card">
          <div class="contact-form-section">
            <h1 class="display-1 font-weight-bold title-dark-brown">
              Contact Us
            </h1>
            <p class="subtitle-1">
              <strong>
                For a personalized solution suited just for you or your business,
                <br/>
                please contact us. Also, please reach out to our support with any questions or concerns.
              </strong>
            </p>
            <v-form>
              <v-text-field label="Name"
                            v-model="form.name"
                            required
                            outlined
                            class="input-field"/>
              <v-text-field label="Email"
                            v-model="form.email"
                            required
                            outlined
                            class="input-field"
                            type="email"/>
              <v-textarea label="Message"
                          v-model="form.message"
                          outlined
                          class="input-field"
                          rows="10"
                          required/>
              <v-btn large
                     color="primary"
                     class="cta-button rounded-button"
                     @click="submitForm"
                     :disabled="!form.name || !form.email || !form.message">
                Submit
              </v-btn>
            </v-form>
          </div>
        </v-card>
      </v-container>
    </v-main>
    <Footer/>
  </v-app>
</template>
<script>
import {defineComponent, ref} from "vue";
import Footer from "@/views/front/components/Footer.vue";
import NavBar from "./components/NavBar.vue";
import {mapGetters} from 'vuex';

export default defineComponent({
  components: {
    NavBar,
    Footer,
  },
  name: "Contact",
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  setup() {
    const form = ref({
      name: "",
      email: "",
      message: "",
    });
    const submitForm = () => {
      console.log("Form submitted:", form.value);
    };
    return {form, submitForm};
  },
});
</script>

<style scoped>
.contact-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: "Roboto", sans-serif;
  background-color: #0a192f;
  padding: 40px 20px;
}

.contact-card {
  background-color: #112240;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  max-width: 800px;
  margin: 0 auto;
}

.contact-form-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #d0d6d6;
}

h1 {
  color: #f0e4d7;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

p {
  color: #b0c4de;
  margin-bottom: 30px;
  font-size: 18px;
}

.input-field {
  margin-bottom: 20px;
  color: #d0d6d6;
}

.input-field .v-input__control {
  background-color: #112240;
  border-radius: 8px;
}

.input-field .v-input__label {
  color: #d0d6d6;
}

.cta-button {
  background-color: #FFA500;
  transition: all 0.3s ease;
  color: white;
  font-weight: bold;
}

.cta-button:hover {
  background-color: #D27D2D;
  transform: scale(1.05);
}

.cta-button:disabled {
  background-color: #555;
}
</style>
