<template>
  <v-app style="background-color: #0a192f;">
    <v-main>
      <v-container fluid
                   style="background-color: #0a192f;">
        <NavBar/>
        <v-row class="justify-center">
          <v-col cols="12"
                 md="8"
                 lg="6">
            <v-card class="pa-10 rounded-card"
                    style="background-color: #112240; color: #d0d6d6; border-radius: 12px;">
              <v-card-title class="text-center">
                Login
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-text-field v-model="email"
                                label="Email"
                                type="email"
                                required
                                outlined
                                color="primary">
                  </v-text-field>
                  <v-text-field v-model="password"
                                label="Password"
                                type="password"
                                required
                                outlined
                                color="primary"
                                class="mt-4">
                  </v-text-field>
                  <v-alert v-if="error"
                           type="error"
                           class="my-3">
                    {{ error }}
                  </v-alert>
                </v-form>
              </v-card-text>
              <v-btn @click="login"
                     color="#FFA500"
                     block> Login
              </v-btn>
              <p class="mt-4 text-center">
                <a href="https://swiftsolutions.app/register"
                   class="register-link">
                  Create your account now
                </a>
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer/>
  </v-app>
</template>
<script>
import axios from "axios";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

export default {
  components: {
    Footer,
    NavBar,
  },
  data() {
    return {
      email: "",
      password: "",
      error: null,
    };
  },
  methods: {
    async login() {
      try {
        this.error = null;
        const response = await axios.post("/account/login", {
          email: this.email,
          password: this.password,
        });
        localStorage.setItem("token", response.data.access_token);

        this.$router.push({name: "dashboard"});
      } catch (err) {
        this.error = "Login failed. Please check your credentials.";
      }
    },
  },
};
</script>
<style scoped>
.v-text-field label {
  color: #d0d6d6 !important;
}

.v-input__control {
  color: white;
}

.v-btn {
  color: white;
}

.register-link {
  color: #58a6ff;
  text-decoration: none;
}

.register-link:hover {
  color: #86c5ff;
}
</style>
