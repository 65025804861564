import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import store from './store';

axios.defaults.baseURL = 'https://swiftsolutions.app/api';
const accountId = 1;
axios.interceptors.request.use((config) => {
    if (!config.params) {
        config.params = {};
    }
    config.params.account_id = accountId;

    return config;
}, (error) => {
    return Promise.reject(error);
});

const app = createApp(App);
app.use(router);
app.use(store);
app.use(vuetify);

app.mount('#app');
