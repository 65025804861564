<template>
  <v-app style="background-color: #0a192f;">
    <v-main>
      <NavBar/>
      <v-container fluid
                   style="background-color: #0a192f;">
        <v-row class="justify-center">
          <v-col cols="12"
                 md="8"
                 lg="6">
            <v-card class="pa-10 rounded-card"
                    style="background-color: #112240; color: #d0d6d6; border-radius: 12px;">
              <v-card-title class="text-center">
                Register
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-text-field v-model="name"
                                label="Name"
                                type="text"
                                required
                                color="primary">
                  </v-text-field>
                  <v-text-field v-model="email"
                                label="Email"
                                type="email"
                                required
                                color="primary"
                                class="mt-4">
                  </v-text-field>
                  <v-text-field v-model="password"
                                label="Password"
                                type="password"
                                required
                                color="primary"
                                class="mt-4">
                  </v-text-field>
                  <v-text-field v-model="company"
                                label="Company"
                                type="text"
                                color="primary"
                                class="mt-4"
                                hint="*Optional"
                                persistent-hint>
                  </v-text-field>
                  <v-text-field v-model="country"
                                label="Country"
                                type="text"
                                color="primary"
                                class="mt-4"
                                hint="*Optional"
                                persistent-hint>
                  </v-text-field>
                  <v-text-field v-model="number"
                                label="Number"
                                type="text"
                                color="primary"
                                class="mt-4"
                                hint="*Optional"
                                persistent-hint>

                  </v-text-field>
                  <v-alert v-if="error"
                           type="error"
                           class="my-3">
                    {{ error }}
                  </v-alert>
                  <v-alert v-if="success"
                           type="success"
                           class="my-3">
                    {{ success }}
                  </v-alert>
                </v-form>
              </v-card-text>
              <v-btn @click="register" color="#FFA500" block> Register
              </v-btn>
              <p class="mt-4 text-center">
                <a href="localhost:8080/register"
                   class="login-link">
                  Already have an account? Log in
                </a>
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer/>
  </v-app>
</template>
<script>
import axios from "axios";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import {mapGetters} from "vuex";

export default {
  components: {
    Footer,
    NavBar,
  },
  data() {
    return {
      name: "",
      email: "",
      password: "",
      company: "",
      country: "",
      error: null,
      success: null,
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    async register() {
      try {
        this.error = null;
        this.success = null;
        const response = await axios.post("/register", {
          name: this.name,
          email: this.email,
          password: this.password,
          company: this.company,
          country: this.country,
        });

        this.success = "Registration successful. You can now log in.";
        this.resetForm();
      } catch (err) {
        this.error = err.response?.data?.message || "Registration failed. Please try again.";
      }
    },
    resetForm() {
      this.name = "";
      this.email = "";
      this.password = "";
      this.company = "";
      this.country = "";
    },
  },
};
</script>
<style scoped>
.login-page {
  background-color: #041421;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-card {
  background-color: #042630;
  color: #d0d6d6;
  border-radius: 12px;
}

.v-text-field label {
  color: #d0d6d6 !important;
}

.v-input__control {
  color: white;
}

.v-btn {
  color: white;
}

.login-link {
  color: #58a6ff;
  text-decoration: none;
}

.login-link:hover {
  color: #86c5ff;
}
</style>
