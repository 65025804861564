<template>
  <v-app>
    <v-container fluid
                 style="background-color: #041421">
      <NavBar>
      </NavBar>
      <v-main class="main-content d-flex flex-column justify-center align-center">
        <div class="privacy-page">
          <v-card class="privacy-card rounded-card pa-5">
            <v-card-title class="card-title text-center">
              Privacy Policy
            </v-card-title>
            <v-card-text class="card-text text-center">
              At Swift Solutions, we are committed to respecting your privacy and safeguarding your information.
              Our platform only collects publicly available information that can be accessed from the internet.
              We do not request or retain any personal data unless it is voluntarily provided through our contact forms
              or similar mechanisms.
              By using this site, you agree to the collection of such publicly available information to enhance your
              experience and functionality.
            </v-card-text>
          </v-card>
        </div>
      </v-main>
      <Footer>
      </Footer>
    </v-container>
  </v-app>
</template>

<script>
import NavBar from '../front/components/NavBar.vue';
import Footer from '../front/components/Footer.vue';

export default {
  components: {
    NavBar,
    Footer,
  },
  name: 'PrivacyPolicy',
};
</script>
<style scoped>
.privacy-page {
  background-color: #041421;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  position: relative;
}

.main-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.privacy-card {
  background-color: #042630;
  color: #d0d6d6;
  border-radius: 12px;
  padding: 40px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.card-title {
  font-size: 28px;
  font-weight: bold;
  color: #86b9b0;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.card-text {
  font-size: 18px;
  color: #d0d6d6;
  line-height: 1.6;
  text-align: center;
}

@media (min-width: 768px) {
  .privacy-card {
    padding: 40px;
  }
}
</style>
